import "../Styles/Branches.css";
import Navbar from "../Components/Navbar";
import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import brnch from "../Images/branches.svg";
// import branch from "../Images/BranchImage.svg";
import { FaPhone } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Branch from "../Components/Branch";
import { countries, branchesByCity, getDepartments } from "../api-nano";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import { BackgroundImage } from "react-image-and-background-image-fade";
import { countryCities } from "../api-nano";

function Branches(props) {
  const { t, i18n } = useTranslation();
  const [branch, setBranch] = useState([]);
  const [allBranches, setAllBranches] = useState([]);

  const [city, setCity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cityLoad, setCityLoad] = useState(false);
  const country = localStorage.getItem("country");

  //fetch cities and sets them according to current chosen country index (props.country)
  //fetch branches
  useEffect(() => {
    // countries().then((response) => {
    //   setCity(response.data.data[props.country].cities);
    // });

    // countryCities(country).then((response) => {
    //   console.log("the citites", response);
    //   setCity(response.data.states.data);
    // });
    let countryId = props.country == 0 ? 1 : 2;

    getDepartments()
      .then((reso) => {
        const data = reso?.data?.data;
        setBranch(data);
        setAllBranches(data);
        console.log("the response from nano", reso?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error ", err);
        setLoading(false);
      });
  }, [props.country]);

  //on changing city if value chosen is "all" , all branches are fetched
  //else branchesByCity api call takes chosen city id and returns branches of that city
  function handleChange(e) {
    setCityLoad(true);
    // setCityValue(e.target.value)
    if (e.target.value == "all") {
      setBranch(allBranches);
      setCityLoad(false);
    } else {
      branchesByCity(e.target.value).then((response) => {
        setBranch(response.data.data);
        setCityLoad(false);
      });
    }
  }

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#dfa400"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="container-all aboutusBody container-fluid fluid">
          <div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <BackgroundImage
                src={brnch}
                width="100%"
                className="branchesBanner"
              />{" "} */}
              {/*<div className="branchesBanner" style={{ backgroundImage: `url(${brnch})` }}>
                    </div>*/}{" "}
            </div>{" "}
            <div className="branchesSort">
              <div
                style={{
                  marginTop: "2em",
                }}
                className="brnchHeader"
              >
                {" "}
                {/*  <p className="branchesTitle">{t("branches.our_branches")}</p>*/}
                <p
                  className={
                    i18n.language == "ar" ? "srtTitle" : "srtTitleEn srtTitle"
                  }
                >
                  {" "}
                  {t("branches.sort")}{" "}
                </p>
                <div className=" sortOptions">
                  <select
                    defaultValue={"all"}
                    onChange={(e) => handleChange(e)}
                  >
                    {" "}
                    {/*<option value="" disabled> {t("branches.city")}</option>*/}{" "}
                    <option value="all"> {t("branches.all")} </option>
                    {city &&
                      city.map((data, index) => (
                        <option key={index} value={data.id}>
                          {" "}
                          {data.name}{" "}
                        </option>
                      ))}{" "}
                  </select>
                </div>
              </div>{" "}
              <hr />
              <div
                style={{
                  marginTop: "3em",
                }}
                class="container-fluid "
              >
                {" "}
                {cityLoad ? (
                  <div className="loader">
                    <BeatLoader
                      color={"#dfa400"}
                      loading={cityLoad}
                      size={30}
                    />
                  </div>
                ) : branch && branch.length == 0 ? (
                  <div
                    style={{
                      height: "50vh",
                    }}
                    className="container-fluid fluid chooseCity"
                  >
                    <div
                      style={{
                        transform: "none",
                      }}
                    >
                      <p className="noNear"> {t("branches.noBranch")} </p>
                    </div>
                  </div>
                ) : (
                  
                  <div class="row gy-5">
                    {/* Todo: onClick go to the menu */}
                    {branch &&
                      branch?.map((data, index) => (
                        <Branch
                          key={index}
                          name={i18n.language == "ar" ? data.name : data.name}
                          address={
                            i18n.language == "ar" ? data.address : data.address
                          }
                          image={data.image?.thumb}
                          id={data.id}
                          latitude={data.latitude}
                          longitude={data.longitude}
                          open={data.is_active}
                          phone={data.phone}
                          workingFrom={"11:00:00"}
                          workingTo={" 23:50:00"}
                          branchId = {data.id}
                        />
                      ))}
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {/* <Footer /> */}
    </div>
  );
}

export default Branches;
