// import "../Styles/Footer.css";
// import { Link } from "react-router-dom";
// import p1 from "../Images/Footer/payment1.svg";
// import p2 from "../Images/Footer/payment2.svg";
// import p3 from "../Images/Footer/payment3.svg";
// import p4 from "../Images/Footer/payment4.svg";
// import vat from "../Images/Footer/vat.svg";
// import logoAr from "../Images/comm1.png";
// import logoEn from "../Images/comm.png";
// import maroof from "../Images/Maroof.svg";
// import { useTranslation } from "react-i18next";

// function Footer() {
//   const { t, i18n } = useTranslation();
//   var dt = new Date();
//   const year = dt.getFullYear();
//   return (
//     <div id="footer" className="footer container-fluid">
//       <div className="container">
//         <div className="row gy-4">
//           <div className="col-sm-4 footerVat">
//             <img
//               className="footerLogo"
//               src={i18n.language == "ar" ? logoAr : logoEn}
//             />
//           </div>{" "}
//           <div className="col-sm-4 paymentMethods">
//             <img src={p3} /> <img src={p4} />{" "}
//           </div>{" "}
//           <div
//             className={
//               i18n.language == "ar"
//                 ? "col-sm-4 footerEndAr"
//                 : "col-sm-4 footerEnd"
//             }
//           >

//           </div>{" "}
//         </div>{" "}
//         <div className="copyRights">
//           <p>
//             {" "}
//             {t("footer.copy_write_parg")}© {year}{" "}
//           </p>{" "}
//           {/* Todo: add developed company to footer section */}
//           <p>
//             {" "}
//             {t("footer.developed_by")}
//             {": "}
//             <a href="https://www.nano2soft.com/ar" target="_blank" className="developedCompany">{t("footer.developed_company")}</a>
//           </p>{" "}
//         </div>{" "}
//       </div>
//     </div>
//   );
// }

// export default Footer;




import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaDotCircle, FaFacebookF, FaFacebookSquare , FaFileInvoiceDollar, FaInstagram, FaPhone, FaTwitterSquare, FaWhatsappSquare, FaYoutube, FaYoutubeSquare } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { GiRotaryPhone } from "react-icons/gi";
import { IoIosMail, IoLogoSnapchat } from "react-icons/io";
import logoAr from "../Images/comm.png";
import "../Styles/Footer.css";
import vat from "../Images/Footer/vat.svg";
import p3 from "../Images/Footer/payment3.svg";
import p4 from "../Images/Footer/payment4.svg";
import mada from "../Images/Footer/mada.png";
import applePay from "../Images/Footer/applePay.jpg";
import qr1 from "../Images/Footer/qr1.png";  // Add your QR code images
import qr2 from "../Images/Footer/qr2.png";
import qr3 from "../Images/Footer/qr3.png";
import { getSettingDataOfPage } from "../api-nano";

function Footer() {
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();
  const [settings, setSettings] = useState(null);
  
  useEffect(() => {
    fetchSettingData();
  }, []);

  const fetchSettingData = async () => {
      try {
          const response = await getSettingDataOfPage();
          setSettings(response.data);
      } catch (error) {
          console.error('Error fetching advert:', error);
      }
  };

  const SocialIcon = ({ href, icon: Icon }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <Icon  style={{"fontSize": "35px", "marginRight": "10px"}}/>
    </a>
  );

  return (
    <footer className="footer">
      <div className="footer-content container">
        <div className="row">
          <div className="col-md-4">
            <img 
              src={         
                settings?.website_logo?.thumb ||
                settings?.website_icon?.thumb ||
                logoAr 
              }
              alt="Rydan Logo" 
              className="footer-logo"
            />
            <p className="footer-description">{settings?.meta_description || t("footer.mission")}</p>
          </div>
          <div className="col-md-4">
            <h3>{t("footer.contact")}</h3>
            <p>{settings?.address || t("footer.address")}</p>

            {/* Social media */}
            <ul className="socail-media-phones-emails">
              {Array.isArray(settings?.phone) ?
                (settings?.phone.map(
                  (
                    phone, index
                  ) => (
                    <li key={index} style={{"marginTop": "4px", "marginBottom": "4px"}}>
                      <Link
                        href={`tel:${phone?.phone_number}`}
                        style={{"display": "flex", "gap": "6px", "justifyContent": "center", "alignItems": "center"}}
                      >
                        {phone?.phone_type === "whats" ? (
                          <RiWhatsappFill style={{"fontSize": "16px"}} />
                        ) : phone?.phone_type === "home" ? (
                          <GiRotaryPhone style={{"fontSize": "16px"}} />
                        ) : (
                          <FaPhone />
                        )}
                        {phone?.phone_number}
                      </Link>
                    </li>
                  )
                ))
                :
                (<p>
                  {t("footer.phone")} : {" "}
                  <Link href={`tel:${settings?.phone}`}>{settings?.phone || '966531833995+'}</Link> 
                </p>)
                }
              {Array.isArray(settings?.email) ?
                (settings?.email.map(
                  (email, index) => (
                    <li key={index} style={{"marginTop": "4px", "marginBottom": "4px"}}>
                      <Link
                        href={`mailto:${email?.email_text}`}
                        style={{"display": "flex", "gap": "6px", "justifyContent": "center", "alignItems": "center"}}
                      >
                        <IoIosMail style={{"fontSize": "19px"}} /> {email?.email_text}
                      </Link>
                    </li>
                  )
                ))
                :
                (<p>
                  {t("footer.email")} : {" "}
                  <Link href={`mailto:${settings?.email}`}>{settings?.email || 'bf2alwajeha@gmail.com'}</Link> 
                </p>)
                }
            </ul>

          </div>
          <div className="col-md-4">
            <h3 style={{"margin": "0 5px 5px 0"}}>{t("footer.follow_us")}</h3>
            <div className="social-icons">
              {Array.isArray(settings?.social_links) &&
                settings?.social_links.length > 0 ? (
                  settings?.social_links?.map((social, index) => (
                    <SocialIcon
                      key={index}
                      href={social?.link}
                      icon={
                        social?.name?.includes("فيسبوك") ||
                        social?.icon?.includes("facebook")
                          ? FaFacebookSquare
                          : social?.name?.includes("يوتيوب") ||
                            social?.icon?.includes("youtube")
                          ? FaYoutubeSquare
                          : social?.name?.includes("انستجرام") ||
                            social?.icon?.includes("instagram")
                          ? FaInstagram
                          : social?.name?.includes("تويتر") ||
                            social?.icon?.includes("twitter")
                          ? FaTwitterSquare
                          : social?.name?.includes("سناب") ||
                            social?.icon?.includes("snapchat")
                          ? IoLogoSnapchat
                          : social?.name?.includes("واتساب") ||
                            social?.icon?.includes("whatsapp")
                          ? FaWhatsappSquare
                          : FaDotCircle
                      }
                    />
                  ))
                ) : (
                  <p></p>
                )}
            </div>

            <div className="col-sm-4 payment-methods ">
              <img src={applePay} /> <img src={mada} /> <img src={vat} style={{"height": "80px"}}/> {" "}
            </div>{" "}

            {/* QR codes  */}
            { /*<div className="qr-codes-container">
              <div className="qr-code-item">
                <a href="https://salamah.998.gov.sa/#/public/certificate-details?code=MVpp1DDpP-jMUra_AULk5w" target="_blank" rel="noopener noreferrer">
                  <div className="qr-code-wrapper">
                    <img src={qr1} alt="QR Code 1" className="qr-code-image" />
                    <div className="qr-overlay">
                      <span>{t('footer.scanQRcode')}</span>
                    </div>
                  </div>
                  <h4 className="qr-title">{t('footer.qr1')}</h4>
                </a>
              </div>

              <div className="qr-code-item">
                <a href="https://qr.mc.gov.sa/info/review?lang=ar&q=19R%209q%20WY%20pBGNCkwSQisw%3D%3D" target="_blank" rel="noopener noreferrer">
                  <div className="qr-code-wrapper">
                    <img src={qr3} alt="QR Code 3" className="qr-code-image" />
                    <div className="qr-overlay">
                      <span>{t('footer.scanQRcode')}</span>
                    </div>
                  </div>
                  <h4 className="qr-title">{t('footer.qr3')}</h4>
                </a>
              </div>
              
              <div className="qr-code-item">
                <a href="https://ebalady.momra.gov.sa/commercial/faces/LicencesDetail.jsf;jsessionid=oeuoY_eybPZqPMy3F_fMrKaYuCQ1KlfJ0auot9mHrvsLxCOEpC7I!1840767660?data=bGljZW5zZU51bWJlcj0zOTA5MTEyODAz" target="_blank" rel="noopener noreferrer">
                  <div className="qr-code-wrapper">
                    <img src={qr2} alt="QR Code 2" className="qr-code-image" />
                    <div className="qr-overlay">
                      <span>{t('footer.scanQRcode')}</span>
                    </div>
                  </div>
                  <h4 className="qr-title">{t('footer.qr2')}</h4>
                </a>
              </div>

            </div>
            */ }
          </div>
        </div>
        <div className="footer-bottom">
        <p className="taxRegistration">
            <FaFileInvoiceDollar />
            <span>{t("footer.taxRegistration")} 300439215400003</span>
          </p>
          <p>&copy; {year} {t("footer.copy_write_parg")}</p>
          <p>{t("footer.developed_by")}: <a href="https://www.nano2soft.com/ar" target="_blank" rel="noopener noreferrer" ><span>{t("footer.developed_company")}</span></a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;