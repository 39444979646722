import React, { useEffect, useState } from "react";

import success from "../Images/success-web.svg";
import failed from "../Images/faild-web.svg";

import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { payAgain_checkout } from "../api-nano";
import Footer from "../Components/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";

function FailedTransaction(props) {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");
  const [state, setState] = useState(false);
  const [cardValid, setCardValid] = useState(false);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  let history = useNavigate();
  const failType = localStorage.getItem("failType");

  //if operation was successful and user clicks on track order
  //user is redirected to /order-track (OrderTracking component in /Pages) with order id as params

  function handleProceed() {
    var id = localStorage.getItem("orderId");
    history(
      generatePath("/order-track/:id", {
        id,
      })
    );
  }

  function payAgain(paymentToken) {
    payAgain_checkout(token, paymentToken, localStorage.getItem("orderId"))
      .then((response) => {
        if (response.data.url !== "" && response.data.url !== null) {
          window.location.href = response.data.url;
        } else if (
          response.data.message == "warning" &&
          response.data.data == "This order has been paid."
        ) {
          setErrorMsgModel(true);
          setMsgAr("لقد تم دفع هذا الطلب بالفعل");
          setMsgEn("This order has been paid.");
          setPaymentLoad(false);
          Frames.init();
        } else if (response.data.message == "warning") {
          setErrorMsgModel(true);
          setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");
          setPaymentLoad(false);
          Frames.init();
        } else {
          if (failType == "order") {
            setState(true);
          } else {
            history("/profile/myWallet");
          }
        }
      })
      .catch(function (err) {
        setErrorMsgModel(true);
        setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
        setMsgEn("Transaction has been failed,please try again");
        setPaymentLoad(false);
        Frames.init();
      });
  }
  return (
    <div>
      <>
        <div
          style={{
            marginTop: "5em",
            marginBottom: "5em",
          }}
          className="container orderSuccess"
        >
          {state ? (
            <div className="shareForm ">
              <img
                style={{
                  marginBottom: "1em",
                }}
                src={success}
              />
              <p
                style={{
                  color: "rgb(99, 50, 53)",
                }}
              >
                {" "}
                {t("payment.success_payment")}{" "}
              </p>
              <button
                style={{
                  display: "inline-block",
                }}
                onClick={handleProceed}
              >
                {" "}
                {t("payment.track_order")}{" "}
              </button>{" "}
            </div>
          ) : (
            <div className="shareForm ">
              <img
                style={{
                  marginBottom: "1em",
                }}
                src={failed}
              />

              <p
                style={{
                  color: "rgb(99, 50, 53)",
                }}
              >
                {" "}
                {t("payment.notSuccess_payment")}{" "}
              </p>

              <div
                className="creditCard"
                style={{
                  margin: "auto",
                  marginTop: "2em",
                  maxWidth: "600px",
                }}
              >
                <Frames
                  cardValidationChanged={(e) => {
                    setCardValid(e.isValid);
                  }}
                  config={{
                    publicKey: "pk_sbox_wxn7ld2c4dskxkd4kcpiilwo2uo",
                    schemeChoice: true,
                  }}
                  cardTokenized={(e) => {
                    payAgain(e.token);
                  }}
                >
                  <CardNumber />
                  <div className="date-and-code">
                    <ExpiryDate />
                    <Cvv />
                  </div>
                  {/* Or if you want to use single frames: */}{" "}
                  {/* <CardFrame /> */}
                  {paymentLoad ? (
                    <div
                      style={{
                        height: "6vh",
                        marginTop: "2em",
                        marginBottom: "1em",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                      className="loader"
                    >
                      <BeatLoader
                        color={"#dfa400"}
                        loading={paymentLoad}
                        size={30}
                      />{" "}
                      <p className="paymentLoad"> {t("payment.processing")} </p>{" "}
                    </div>
                  ) : (
                    <button
                      disabled={!cardValid}
                      id="pay-button"
                      onClick={() => {
                        setPaymentLoad(true);
                        Frames.submitCard();
                      }}
                    >
                      {t("payment.pay_again")}{" "}
                    </button>
                  )}{" "}
                </Frames>{" "}
                {failType == "order" ? (
                  <button
                    style={{
                      display: "inline-block",
                      marginTop: "0",
                      width: "100%",
                      textAlign: "center",
                    }}
                    onClick={handleProceed}
                  >
                    {" "}
                    {t("payment.track_order")}{" "}
                  </button>
                ) : null}{" "}
                {i18n.language == "ar" ? (
                  <span
                    className="errMsg"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    {errorMsgModel ? msgAr : null}{" "}
                  </span>
                ) : (
                  <span
                    className="errMsg"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {" "}
                    {errorMsgModel ? msgEn : null}{" "}
                  </span>
                )}{" "}
              </div>
            </div>
          )}{" "}
        </div>
      </>

      {/* <Footer /> */}
    </div>
  );
}

export default FailedTransaction;
