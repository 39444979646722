import React, { useState, useEffect } from 'react';
import { getMyPoints } from '../api-nano';
import { useTranslation } from 'react-i18next';
import { FaCoins, FaPlus, FaMinus, FaExchangeAlt, FaWallet } from 'react-icons/fa';
import '../Styles/MyPoints.css';
import { Link } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import Footer from '../Components/Footer';
import { BeatLoader } from 'react-spinners';

const MyPoints = () => {
  const [pointsData, setPointsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const verified = JSON.parse(localStorage.getItem("verified"));


  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await getMyPoints(token);
        setPointsData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching points:', error);
        setLoading(false);
      }
    };

    fetchPoints();
  }, []);

  if (loading) {
    return (
      <div className="loader profileLoader">
        <BeatLoader color={"#dfa400"} loading={loading} size={30} />
      </div>
    );
  }

  if (!pointsData) {
    return <div className="error">Error loading points data</div>;
  }

  const PointCard = ({ icon, title, price, value, description }) => (
    <div className="point-card">
        <div className="point-icon">
            <div>{icon}</div>
            <h3>{price} {t('myPoints.riyal')}</h3>
        </div>
        <div className="point-info">
            <h3>{title}</h3>
            <p className="point-value">{value}</p>
            <p className="point-description">{description}</p>
        </div>
    </div>
  );

  return (
    <>
    { !token || !verified ? ( //to check if user is not logged in the navbar will show a sign-in option instead of user profile
      <div className="my-points-container container-all" style={{textAlign: "center"}}>
        <h1 className="page-title">{t('myPoints.title')}</h1>
        <p className="description">{t('myPoints.description1')}</p>
        <p className="description">{t('myPoints.description2')} <span style={{color:"#000000"}}>{t('myPoints.description3')}</span></p>
        <p className="description" style={{marginTop: "40px"}}>{t('myPoints.description4')}</p>
        <Link to="/login">
                <button className="loginButton">
                  {" "}
                  {t("nav.signIn")}{" "}
                </button>
              </Link>
        </div>
      
    ) :(
    <div className="my-points-container container-fluid container-all" >
      <h1 className="page-title">{t('myPoints.title')}</h1>
      
      <div className="points-summary">
        <PointCard 
          icon={<FaCoins />}
          title={t('myPoints.totalPoints')}
          price={pointsData.sum_total}
          value={pointsData.sum_points}
          description={t('myPoints.totalPointsDesc')}
        />
        <PointCard 
          icon={<FaWallet />}
          title={t('myPoints.availablePoints')}
          price={pointsData.sum_total_available}
          value={pointsData.sum_points_available}
          description={t('myPoints.availablePointsDesc')}
        />
      </div>

      <div className="points-details">
        <PointCard 
          icon={<FaPlus />}
          title={t('myPoints.addedPoints')}
          price={pointsData.sum_total_add}
          value={pointsData.sum_points_add}
          description={t('myPoints.addedPointsDesc')}
        />
        <PointCard 
          icon={<FaMinus />}
          title={t('myPoints.removedPoints')}
          price={pointsData.sum_total_remove}
          value={pointsData.sum_points_remove}
          description={t('myPoints.removedPointsDesc')}
        />
        <PointCard 
          icon={<FaExchangeAlt />}
          title={t('myPoints.usedPoints')}
          price={pointsData.sum_total_out}
          value={pointsData.sum_points_out}
          description={t('myPoints.usedPointsDesc')}
        />
      </div>

      {/* <div className="points-value">
        <h2>{t('myPoints.pointsValue')}</h2>
        <p>{t('myPoints.pointPrice')}: {pointsData.point_price}</p>
        <p>{t('myPoints.totalValue')}: {pointsData.sum_total}</p>
        <p>{t('myPoints.availableValue')}: {pointsData.sum_total_available}</p>
      </div> */}
    </div>
    )}
    {" "}
        {/* <Footer /> */}
    </>
  );
};

export default MyPoints;