import "../Styles/CompanySales.css";
import Navbar from "../Components/Navbar";
import { useRef, useState, useEffect } from "react";
import Footer from "../Components/Footer";

import { useTranslation } from "react-i18next";

import Contact from "../Components/ContactUs";
import Slider from "../Components/Slider";
import { BeatLoader } from "react-spinners";
import { getBanners } from "../api-nano";

function ContactUs(props) {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  //fetch images for slider
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    let language = i18n.language == "ar" ? "ar" : "en";
    let countryId = props.country == 0 ? 1 : 2;

    getBanners().then((response) => {
      const data = response.data.data;
      setBanners(data);

      setLoading(false);
    });
  }, [i18n.language, props.country]);

  return (
    <div>
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#dfa400"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="aboutusBody">
          <Slider banners={banners} />

          <Contact classname="container-fluid fluid" />
        </div>
      )}

      {/* <Footer /> */}
    </div>
  );
}

export default ContactUs;
