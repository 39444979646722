import "../Styles/Login.css";
import food from "../Images/Login/food.png";
import brown from "../Images/Login/background.svg";
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useTranslation } from "react-i18next";
// import { app } from "../Firebase/firebaseConfig";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomerInfo, SMS, countries } from "../api-nano";
import Footer from "../Components/Footer";
import { login } from "../api-nano";
// import { getAnalytics, logEvent } from "firebase/analytics";
import * as _ from "lodash";
import { countriesData } from "../CountryCodes";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

function Login(props) {
  const [hidden, setHidden] = useState(false);
  const { t, i18n } = useTranslation();
  //   const analytics = getAnalytics(app);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [loading, setLoading] = useState(false);

  const [errorMsgModel, setErrorMsgModel] = useState(false);
  let history = useNavigate();

  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

  const Schema = yup.object().shape({
    countryId: yup.string().required("validation.country_required"),
    phone: yup.string().required("validation.phone_required"),
    // .matches(phoneRegEx, "validation.phone_not_valid"),
    password: yup
      .string()
      .min(8, "validation.at_least")
      .required("validation.password_required"),
  });

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
      countryId: 249
    },
    validationSchema: Schema,
    onSubmit,
  });

  const passRef = useRef();
  const phoneRef = useRef();
  const countryRef = useRef();

  const [country, setCountry] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    countries().then((response) => {
      const data = response.data.data;
      setCountry(data);
      console.log("the countries is", data);
    });
  }, []);


  //onSubmitting for login (takes: phone number and password):
  //if user data is old (determined by api) user is redirected to "/code" , and his phone number,token and verification state are stored for use in other screens
  //if user is verified his data is fetched (using CustomerInfo api call) then stored then he's directed to the home screen
  //if user is not verified, user is redircted to "/verify" and sent an otp
  async function onSubmit(event) {
    setLoading(true);
    const selectCount = country.find((c) => c.id == countryRef.current.value);
    let dial_code = "+968";
    if (selectCount.code != "")
      dial_code = countriesData.find(
        (x) => x.code == selectCount.code
      )?.dial_code;

    console.log("data is ", selectCount);

    // Todo: change a normal input to PhoneInput in Login page 
    const data = {
      loginAttribute: "mobile",
      // username: nameRef.current.value,
      // email: "testtest@gmail.com",
      country: countryRef.current.value,
      login: phoneRef.current.value.replace(/\s+/g, ''),
      password: passRef.current.value,
      profile: 1,
      // country_Id: countryRef.current.value,
      // terms: termsRef.current.value,
    };
    // const data = {
    //   phone: phoneRef.current.value,
    //   password: passRef.current.value,
    //   pushToken: localStorage.getItem("pushToken")
    //     ? localStorage.getItem("pushToken")
    //     : "",
    // };
    console.log("data is ", data);

    login(data)
      .then((response) => {
        console.log("login is ok", response.data);
        // CustomerInfo(response.data.data.access_token).then((response) => {
        //   //   logEvent(analytics, "login", {
        //   //     phone: data.phone,
        //   //     name: response.data.data.name,
        //   //   });
        // });
        if (response.data?.status == true) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userPhone", data.profile.mobile);
          // CustomerInfo(response.data.data.access_token).then((response) => {
          //   props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart
          localStorage.setItem("userName", response.data?.profile?.name);
          localStorage.setItem("country", response.data?.profile?.country_id);
          var frm = document.getElementsByName("loginForm")[0];
          localStorage.setItem("verified", JSON.stringify(true));
          frm.reset();
          if (localStorage.getItem("prevScreen") == "redirect") {
            //redirect is set in screens related to user sign-up / sign-in so that on logging in user is redirected to home screen instead
            history("/");
          } else {
            history(-1); //other than the above mentiond screens user will redirect to previous screen (last visited before login screen) on logging in
          }
          // });
        } else {
          setMsgAr(response.data.error);
          setMsgEn("Invalid phone or password");
          setErrorMsgModel(true);
          setLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 422) {
          setMsgAr("خطأ في رقم الهاتف او كلمة المرور");

          setMsgEn("Invalid phone or password");
          setErrorMsgModel(true);
          setLoading(false);
        }
      });
  }

  //for hiding and showing password

  function show() {
    setHidden(!hidden);
    var x = document.getElementById("pass");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <div>
      <div className="container-all loginContainer container-fluid fluid">
        <div className=" login">
          {" "}
          {loading ? (
            <div
              style={{
                height: "60vh",
              }}
              className="loader"
            >
              <BeatLoader color={"#dfa400"} loading={loading} size={30} />
            </div>
          ) : null}{" "}
          <div
            style={
              loading
                ? {
                    display: "none",
                  }
                : null
            }
            className="row"
          >
            <div className="col">
              <div className="loginTitle">
                <p> {t("Login.login")} </p>
              </div>
              <form
                className="loginForm"
                name="loginForm"
                onSubmit={formik.handleSubmit}
              >
                <label> {t("Login.country")} </label>
                <select
                  value={formik.values.countryId}
                  id="countryId"
                  name="countryId"
                  ref={countryRef}
                  required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.countryId && formik.touched.countryId
                      ? "invalid"
                      : null
                  }
                >
                  <option disabled value="">
                    {" "}
                    {t("Login.chooseCountry")}{" "}
                  </option>{" "}
                  {country &&
                    country.map((data, index) => (
                      <option key={index} value={data.id}>
                        {" "}
                        {data.name == "Yemen"? "اليمن": data.name}{" "}
                      </option>
                    ))}
                </select>{" "}
                <span>
                  {" "}
                  {formik.errors.countryId && formik.touched.countryId
                    ? t(`${formik.errors.countryId}`)
                    : null}{" "}
                </span>
                <label> {t("contact.byPhone")} </label>{" "}

                {/* Todo: change a normal input to PhoneInput in Login page */}
                <PhoneInput
                    className="phoneNumber" 
                    id="phone"
                    name="phone"
                    countryCallingCodeEditable={false}
                    defaultCountry="SA"  
                    international    // To get a Country code
                    ref={phoneRef}
                    required={true}
                    onBlur={formik.handleBlur}
                    placeholder= {t("contact.byPhone")}
                    value={formik.values.phone}
                    onChange={(value) => formik.setFieldValue("phone", value)}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      className: ((formik.errors.phone && formik.touched.phone) || errorMsgModel) ? "invalid" : null
                    }}
                  />
                  {/* {formik.errors.phone && formik.touched.phone && (
                    <div className="error">{formik.errors.phone}</div>
                  )} */}


                {/* <input
                  type="text"
                  maxLength={15}
                  placeholder={t("contact.byPhone")}
                  value={formik.values.phone}
                  id="phone"
                  name="phone"
                  ref={phoneRef}
                  required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    (formik.errors.phone && formik.touched.phone) ||
                    errorMsgModel
                      ? "invalid"
                      : null
                  }
                />
                 */}
                {" "}
                <span>
                  {" "}
                  {formik.errors.phone && formik.touched.phone
                    ? t(`${formik.errors.phone}`)
                    : null}{" "}
                </span>{" "}
                <label> {t("Login.pass")} </label>{" "}
                <div className="loginPass">
                  <input
                    id="pass"
                    style={{
                      width: "100%",
                    }}
                    type="password"
                    value={formik.values.password}
                    name="password"
                    ref={passRef}
                    required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      (formik.errors.password && formik.touched.password) ||
                      errorMsgModel
                        ? "invalid"
                        : null
                    }
                  />{" "}
                  <span>
                    {" "}
                    {formik.errors.password && formik.touched.password
                      ? t(`${formik.errors.password}`)
                      : null}{" "}
                  </span>{" "}
                  {hidden ? (
                    <AiOutlineEyeInvisible
                      onClick={show}
                      size={30}
                      className={i18n.language == "ar" ? "eye" : "eyeEn"}
                      style={
                        i18n.language == "ar"
                          ? {
                              right: "auto",
                              left: "8px",
                            }
                          : null
                      }
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={show}
                      size={30}
                      className={i18n.language == "ar" ? "eye" : "eyeEn"}
                      style={
                        i18n.language == "ar"
                          ? {
                              right: "auto",
                              left: "8px",
                            }
                          : null
                      }
                    />
                  )}{" "}
                  <Link to="/forget">
                    {" "}
                    <p
                      style={
                        i18n.language == "ar"
                          ? {
                              right: "auto",
                              left: "0",
                            }
                          : null
                      }
                      className={
                        formik.errors.password && formik.touched.password
                          ? "forgot forgotInvalid"
                          : "forgot"
                      }
                    >
                      {" "}
                      {t("Login.forget")}{" "}
                    </p>
                  </Link>
                </div>
                {i18n.language == "ar" ? (
                  <span
                    style={{
                      margin: "1em 0",
                    }}
                  >
                    {" "}
                    {errorMsgModel ? msgAr : null}{" "}
                  </span>
                ) : (
                  <span
                    style={{
                      margin: "1em 0",
                    }}
                  >
                    {" "}
                    {errorMsgModel ? msgEn : null}{" "}
                  </span>
                )}{" "}
                <button
                  style={
                    !errorMsgModel
                      ? {
                          marginTop: "2em ",
                        }
                      : null
                  }
                  type="submit"
                >
                  {" "}
                  {t("Login.start")}{" "}
                </button>{" "}
              </form>{" "}
              <div className="register">
                <p> {t("Login.dontHave")} </p>{" "}
                <Link to="/register">
                  {" "}
                  <button> {t("Login.register")} </button>
                </Link>{" "}
              </div>{" "}
            </div>{" "}
            <div
              className={
                i18n.language == "ar" ? "col loginImagesAr" : "col loginImages"
              }
            >
              <img className="img1" src={brown} />{" "}
              <img className="img2" src={food} />{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      {/* <Footer /> */}
    </div>
  );
}

export default Login;
