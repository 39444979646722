import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

import { useState, useEffect } from "react";

// import { onMessage } from "firebase/messaging";
import { Get_Basket } from "./api-nano";
import { BeatLoader } from "react-spinners";
import Navbar from "./Components/Navbar";
import Branches from "./pages/Branches";
import AboutUs from "./pages/AboutUs";
import CompanySales from "./pages/CompanySales";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import MyOrders from "./pages/MyOrders";
import MyAddresses from "./pages/MyAddresses";
import PersonalData from "./pages/PersonalData";
import OrderNow from "./pages/OrderNow";
import FromBranch from "./pages/FromBranch";
import MainMenu from "./pages/MainMenu";
import { CategoryContext } from "./Context/CategoryContext";
import SingleProduct from "./pages/SingleProduct";
import CartProcess from "./pages/CartProcess";
import Verify from "./pages/Verify";
import { createGlobalState } from "react-use";
import AddressPage from "./pages/AddressPage";
import FailedTransaction from "./pages/FailedTransaction";
import OrderDetails from "./Components/OrderDetails";
import MyPoints from "./pages/MyPoints";
import MyPointsProfile from "./pages/MyPointsProfile";
import Footer from "./Components/Footer";
import MyProposals from "./pages/MyProposals";

export const useBasketCount = createGlobalState(0);

function App() {
  const [country, setCountries] = useState(
    localStorage.getItem("country") ? localStorage.getItem("country") : 0
  );
  const [itemsCount, setItemsCount] = useBasketCount();
  const [categ, setCateg] = useState(2);
  const [cartItems, setCartItem] = useState(0);
  const [cartChange, setCartChange] = useState(false);
  const [notify, setNotify] = useState(false);
  const [toast, setToast] = useState(false);
  const [cartId, setCartId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState(false);

  // function handleMessage() {
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     setToast({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     setNotify(true);
  //     // ...
  //   });
  // }
  // handleMessage();

  /*
      window.webengage.onReady(function () {
        window.webengage.webpush.prompt()
        window.webengage.notification.render();
          var subscribed = window.webengage.webpush.isSubscribed();
      
          if (subscribed) {
              console.log('User is already subscribed');
          } else {
              console.log('User is not yet subscribed');
          }
        });*/

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token) {
      Get_Basket(token)
        .then((response) => {
          console.log(response?.data?.count);
          if (response?.data?.content) {
            setCartItem(response?.data?.count);
            // setCartId(response?.data?.);
          } else {
            setCartItem(0);
          }
        })
        .catch((err) => {
          setCartItem(0);
        });
    }
  }, [cartChange, country]);

  function handleChangeLang() {
    setLoading(true);
    setTimeout(function () {
      setLoading(false);
    }, 500);
  }

  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#dfa400"} loading={loading} size={30} />
        </div>
      ) : null}{" "}
      <div
        style={
          loading
            ? {
                display: "none",
              }
            : null
        }
      >
          <Navbar
            setProcess={setProcess}
            process={process}
            setCartItem={setCartItem}
            handleChangeLang={handleChangeLang}
            setCartChange={setCartChange}
            cartChange={cartChange}
            cartItems={cartItems}
            cartId={cartId}
            setCountries={setCountries}
            setLoading={setLoading}
          />
          {/* <Toast
            onClose={() => setNotify(false)}
            show={notify}
            delay={4000}
            autohide
          >
            <Toast.Header>
              <p> {toast.title} </p>{" "}
            </Toast.Header>{" "}
            <Toast.Body> {toast.body} </Toast.Body>{" "}
          </Toast> */}
          <CategoryContext.Provider
            value={{
              categ,
              setCateg,
            }}
          >
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Home
                    cartId={cartId}
                    setCartItem={setCartItem}
                    country={country}
                    cartItems={cartItems}
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/branches"
                element={<Branches country={country} />}
              />
              <Route
                exact
                path="/about-us"
                element={<AboutUs country={country} />}
              />
              {/* <Route exact path="/vat" element={<Vat />} />{" "} */}
              <Route
                exact
                path="/login"
                element={
                  <Login
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route exact path="/register" element={<Register />} />{" "}
              <Route exact path="/forget" element={<ForgetPassword />} />{" "}
              <Route exact path="/menu" element={<MainMenu />} />{" "}
              {/* <Route
              exact
              path="/profile/myWallet"
              element={
                <Transactions country={country} setCartItem={setCartItem} />
              }
            /> */}
              <Route
                exact
                path="/order-now"
                element={
                  <OrderNow
                    cartId={cartId}
                    cartItems={cartItems}
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/branch"
                element={
                  <FromBranch
                    country={country}
                    cartId={cartId}
                    cartItems={cartItems}
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/cart"
                element={
                  <CartProcess
                    setProcess={setProcess}
                    process={process}
                    cartChange={cartChange}
                    setCartItem={setCartItem}
                    setCartChange={setCartChange}
                  />
                }
              />
              {/* <Route
                  exact
                  path="/free-meals"
                  element={
                    <FreeMeals
                      cartChange={cartChange}
                      setCartChange={setCartChange}
                    />
                  }
                /> */}
              <Route
                exact
                path="/meals/:id"
                element={
                  <SingleProduct
                    cartChange={cartChange}
                    setCartItem={setCartItem}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/profile/myOrders"
                element={<MyOrders setCartItem={setCartItem} />}
              />
              <Route
                exact
                path="/profile/myAddresses"
                element={
                  <MyAddresses country={country} setCartItem={setCartItem} />
                }
              />
              <Route
                exact
                path="/profile/myPoints"
                element={
                  <MyPointsProfile setCartItem={setCartItem} />
                }
              />
              <Route
                exact
                path="/profile/myProposals"
                element={
                  <MyProposals setCartItem={setCartItem} />
                }
              />
              <Route
                exact
                path="/profile/myData"
                element={<PersonalData setCartItem={setCartItem} />}
              />
              <Route
                exact
                path="/address"
                element={
                  <AddressPage country={country} setCartItem={setCartItem} />
                }
              />
              {/* <Route exact path="/invoice-success" element={<InvoiceSuccess />} />
            <Route exact path="/register" element={<Register />} />{" "}
            <Route exact path="/forget" element={<ForgetPassword />} />{" "}
            <Route exact path="/changePassword" element={<ChangePass />} />{" "} */}
              <Route
                exact
                path="/company-sales"
                element={<CompanySales country={country} />}
              />
              <Route
                exact
                path="/payment-fail"
                element={<FailedTransaction />}
              />
              {/* <Route
              exact
              path="/about-us"
              element={<AboutUs country={country} />}
            />
            <Route
              exact
              path="/code"
              element={
                <Otp cartChange={cartChange} setCartChange={setCartChange} />
              }
            />
           
            {" "}
            */}
              {/* <Route exact path="/awafi" element={<Awafi />} /> */}
              <Route
                exact
                path="/verify"
                element={
                  <Verify
                    cartChange={cartChange}
                    setCartChange={setCartChange}
                  />
                }
              />
              <Route
                exact
                path="/contact-us"
                element={<ContactUs country={country} />}
              />
              {/* Todo: Add Order details & My Points Route */}
              <Route exact path="/order-track/:id" element={<OrderDetails />} />
              <Route exact path="/points" element={<MyPoints />} />

            </Routes>{" "}
          </CategoryContext.Provider>{" "}
          <Footer />
        {" "}
      </div>{" "}
    </div>
  );
}

export default App;
