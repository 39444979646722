import "../Styles/CompanySales.css";

import React, { useState, useEffect, useRef } from "react";

import call from "../Images/call.svg";
import Facebook from "../Images/Facebook.svg";
import twitter from "../Images/twitter.svg";
import instagram from "../Images/instagram.svg";
import youtube from "../Images/threads.png";
import { useTranslation } from "react-i18next";
import { phones, contact } from "../api-nano";
import ContactSuccess from "../Components/ContactSuccess";
import * as yup from "yup";
import { useFormik } from "formik";
import Slider from "../Components/Slider";
import { BeatLoader } from "react-spinners";
import Footer from "./Footer";

function ContactUs(props) {
  const { t, i18n } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [phone, setphone] = useState([]);

  const [loading, setLoading] = useState(true);

  let nameRegEx = /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
  let emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const Schema = yup.object().shape({
    phone: yup
      .string()
      .required("validation.phone_required")
      .matches(phoneRegEx, {
        message: "validation.phone_not_valid",
      }),
    name: yup.string().required("validation.name_required").matches(nameRegEx, {
      message: "validation.name_not_valid",
    }),
    message: yup.string().required("validation.message_required"),
    email: yup
      .string()
      .required("validation.email_required")
      .matches(emailRegx, {
        message: "validation.invalid_email",
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: Schema,
    onSubmit,
  });
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const nameRef = useRef();

  //fetch phones for contact

  useEffect(() => {
    // phones().then((response) => {
    // const data = response.data.data;
    // setphone(data);
    // console.log(response);
    setLoading(false);
    // });
  }, []);

  //takes user name,phone,email and message
  //on success a success message is displayed and form is reset
  function onSubmit() {
    const data = {
      name: nameRef.current.value,
      phone: phoneRef.current.value,
      email: emailRef.current.value,
      msg: messageRef.current.value,
    };

    contact(data).then((response) => {
      setLoading(true);
      window.location.href = `https://api.whatsapp.com/send/?phone=966531833995&text=${"الاسم:"}+${
        data.name
      }%0A${"رقم الجوال:"}+${data.phone}%0A${"البريد الإلكتروني:"}+${
        data.email
      }%0A${"الرسالة:"}+${data.msg}`;

      // setSuccess(true);
      formik.resetForm();
    });
  }

  return (
    <div>
      {" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#dfa400"} loading={loading} size={30} />
        </div>
      ) : (
        <div>
          {success ? <ContactSuccess setModal={setSuccess} /> : null}

          <div
            style={{
              marginBottom: "5em",
            }}
          >
            <Slider />

            <div className={props.classname}>
              <p className="cuHeader"> {t("contact.KnowMore")} </p>{" "}
              <div className="row gy-5">
                <div className="col-lg-6">
                  <div className="alignCu">
                    <p className="cuTitles"> {t("contact.form")} </p>{" "}
                    <div className="contactForm">
                      <form name="contactUs" onSubmit={formik.handleSubmit}>
                        <label> {t("Login.yourName")} </label>{" "}
                        <input
                          type="text"
                          placeholder={t("Login.yourName")}
                          value={formik.values.name}
                          id="name"
                          name="name"
                          ref={nameRef}
                          required={true}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.name && formik.touched.name
                              ? "invalid"
                              : null
                          }
                        />{" "}
                        <span>
                          {" "}
                          {formik.errors.name && formik.touched.name
                            ? t(`${formik.errors.name}`)
                            : null}{" "}
                        </span>
                        <label> {t("Login.enterPhone")} </label>{" "}
                        <input
                          type="text"
                          placeholder={t("Login.yourPhone")}
                          value={formik.values.phone}
                          id="phone"
                          name="phone"
                          ref={phoneRef}
                          required={true}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.phone && formik.touched.phone
                              ? "invalid"
                              : null
                          }
                        />{" "}
                        <span>
                          {" "}
                          {formik.errors.phone && formik.touched.phone
                            ? t(`${formik.errors.phone}`)
                            : null}{" "}
                        </span>
                        <label> {t("contact.email")} </label>{" "}
                        <input
                          type="text"
                          placeholder={t("contact.email")}
                          value={formik.values.email}
                          id="email"
                          name="email"
                          ref={emailRef}
                          required={true}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.email && formik.touched.email
                              ? "invalid"
                              : null
                          }
                        />{" "}
                        <span>
                          {" "}
                          {formik.errors.email && formik.touched.email
                            ? t(`${formik.errors.email}`)
                            : null}{" "}
                        </span>
                        <label> {t("contact.message")} </label>{" "}
                        <textarea
                          value={formik.values.message}
                          id="message"
                          name="message"
                          ref={messageRef}
                          required={true}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.message && formik.touched.message
                              ? "invalid"
                              : null
                          }
                        />{" "}
                        <span>
                          {" "}
                          {formik.errors.message && formik.touched.message
                            ? t(`${formik.errors.message}`)
                            : null}{" "}
                        </span>
                        <div
                          style={
                            i18n.language == "ar"
                              ? {
                                  textAlign: "left",
                                  marginTop: "1.5em",
                                }
                              : {
                                  textAlign: "right",
                                  marginTop: "1.5em",
                                }
                          }
                        >
                          <button type="submit"> {t("contact.send")} </button>{" "}
                        </div>{" "}
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div>
                    <p
                      style={{
                        marginBottom: "0em",
                      }}
                      className="cuTitles"
                    >
                      {" "}
                      {t("contact.administration")}{" "}
                    </p>
                    <div className="contactPhone">
                      <div
                        style={{
                          marginBottom: "1em",
                          marginTop: "0",
                        }}
                      >
                        <p> {t("contact.administration-text")} </p>
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div>
                    <p className="cuTitles"> {t("contact.byPhone")} </p>
                    <div className="contactPhone">
                      <div
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <p> 966531833995+ </p>{" "}
                        <a href={`tel:966531833995+`}>
                          {" "}
                          <button>
                            {" "}
                            <img
                              className={
                                i18n.language == "ar" ? "callIcon" : null
                              }
                              src={call}
                            />
                          </button>
                        </a>
                      </div>
                    </div>{" "}
                  </div>
                  <div
                    className={
                      i18n.language === "ar"
                        ? "cuSocials "
                        : "cuSocials cuSocialsEn"
                    }
                  >
                    <p className="cuTitles"> {t("contact.followUs")} </p>{" "}
                    <a
                      href="#"
                      // target="_blank"
                    >
                      {" "}
                      <button
                        style={{
                          margin: "0",
                        }}
                      >
                        {" "}
                        <img
                          style={{ width: "30px", height: "30px" }}
                          src={youtube}
                        />
                      </button>{" "}
                    </a>
                    <a
                      href="#"
                      // target="_blank"
                    >
                      {" "}
                      <button>
                        {" "}
                        <img src={Facebook} />
                      </button>{" "}
                    </a>
                    <a 
                      href="#" 
                      // target="_blank"
                    >
                      {" "}
                      <button className="twitter">
                        {" "}
                        <img src={twitter} />
                      </button>{" "}
                    </a>
                    <a
                      href="#"
                      // target="_blank"
                    >
                      {" "}
                      <button>
                        {" "}
                        <img src={instagram} />
                      </button>{" "}
                    </a>
                  </div>{" "}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
}

export default ContactUs;
