import "../Styles/MainMenu.css";
import Footer from "../Components/Footer";
import { useEffect, useState, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import MenuItem from "../Components/MenuItem";
import { categories, products, getAllProducts } from "../api-nano";
import { CategoryContext } from "../Context/CategoryContext";
// import { getAnalytics, logEvent } from "firebase/analytics";
// import { app } from "../Firebase/firebaseConfig";
import { CustomerInfo } from "../api-nano";
import { BeatLoader } from "react-spinners";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import timthumb from "../Images/timthumb.png";

function MainMenu() {
  const { categ, setCateg } = useContext(CategoryContext);
  const [category, setCategory] = useState([]);
  const [categIndex, setCategIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [product, setProduct] = useState([]);
  const [activeCategory, setActiveCategory] = useState(categ); //used uceContext so when user returns from a single prodcut screen the category isn't changed back to first category
  const [searchTerm, SetSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [categLoading, setCategLoading] = useState(false);
  const [overflow, setOverflow] = useState(false);

  const [noProduct, setNoProduct] = useState(false);
  //   const analytics = getAnalytics(app);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  //fetch categories
  //fetch customer data for analytics and webengage use
  //fetch meals depending on current active category
  //rerenders everytime the category changes

  // ... existing state declarations ...
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Fetch categories
    categories().then((response) => {
      const data = response.data.data;
      // console.log("the categories is", data);
      setCategory(data);
    });

    // Todo: Search form all products
    // Fetch all products
    getAllProducts(localStorage.getItem("branchId")).then((response) => {
      // console.log("all products: ", response.data.data);
      setAllProducts(response.data.data);
    });

    products(activeCategory, localStorage.getItem("branchId")).then(
      (response) => {
        console.log("the products is ", response.data.data);
        const data = response.data.data;
        setProduct(data);
        // console.log(data);
        setLoading(false);
        setCategLoading(false);
      }
    );
  }, [activeCategory]);

  // Modify the handleSearch function
  function handleSearch(e) {
    SetSearchTerm(e.target.value);

    const searchedProducts = allProducts.filter((data) => {
      // if (e.target.value === "") {
      //   return data.categoryId == activeCategory; // Show only active category products when search is empty
      // } else 
      if (
        data.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        data.description.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true; // Include in results if name or description matches
      }
      return false;
    });

    setProduct(searchedProducts); // Update the displayed products

    if (searchedProducts.length === 0) {
      setNoProduct(true);
    } else {
      setNoProduct(false);
    }
  }

  /*
    function isEllipsisActive(){
     let e= document.getElementsByClassName("categoryScroll");

     if(e[0]){
    if(e[0].offsetWidth < e[0].scrollWidth){

      document.getElementById("overF").style.display="block";
      console.log(e[0].offsetWidth < e[0].scrollWidth)
    } 

    else{

      document.getElementById("overF").style.display="none";
       console.log(e[0].offsetWidth < e[0].scrollWidth)
    }

    }}
      */

  //isEllipsisActive();

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  //checks if categoryScroll div overflows to display an indecator (arrow)
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);

    let e = document.getElementsByClassName("categoryScroll");

    if (e[0]) {
      if (e[0].offsetWidth < e[0].scrollWidth) {
        document.getElementById("overF").style.display = "block";
        console.log(e[0].offsetWidth < e[0].scrollWidth);
      } else {
        document.getElementById("overF").style.display = "none";
        console.log(e[0].offsetWidth < e[0].scrollWidth);
      }
    }
  }, []);

  return (
    <div>
      {" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#dfa400"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="container-all mainMenu container-fluid fluid">
          <div
            className={
              i18n.language == "ar"
                ? "mmSideNav "
                : "mmSideNavEn mmSideNavEn-mobile"
            }
          >
            <p className="mmsideNav-title"> {t("party.categories")} </p>{" "}
            <div className="categoryScroll">
              {" "}
              {category &&
                category.map((data, index) => (
                  <div
                    onClick={() => {
                      if (data.id !== activeCategory) {
                        setActiveCategory(data.id);
                        setCategLoading(true);
                        setCategIndex(index);
                        setCateg(data.id);
                      }
                    }}
                    key={index}
                    className={
                      activeCategory == data.id
                        ? "mmsideNav-link mmsideNav-linkActive"
                        : "mmsideNav-link"
                    }
                  >
                    <img src={data.image?.thumb ?? timthumb} alt="chicken" />
                    <p>{data.name}</p>{" "}
                  </div>
                ))}{" "}
            </div>{" "}
          </div>
          <div id="overF">
            {" "}
            {i18n.language == "en" ? (
              <IoIosArrowForward size={25} />
            ) : (
              <IoIosArrowBack size={25} />
            )}
          </div>
          <div
            style={
              i18n.language == "ar"
                ? null
                : {
                    paddingLeft: "20px",
                    paddingRight: "0",
                  }
            }
            className="mmItem"
          >
            <div
              className={i18n.language == "ar" ? "mmFlex" : "mmFlex mmFlexEn"}
            >
              <p className="mmItem-title"> {t("party.meals")} </p>{" "}
              <div>
                <input
                  type="text"
                  placeholder={t("party.search")}
                  onChange={(e) => handleSearch(e)}
                />{" "}
                <BsSearch
                  size={27}
                  className={
                    i18n.language == "ar" ? "mmIcon" : " mmIcon mmIconEn"
                  }
                />{" "}
              </div>{" "}
            </div>{" "}
            <div>
              {categLoading ? (
                <div
                  style={{
                    height: "50vh",
                  }}
                  className="loader"
                >
                  <BeatLoader
                    color={"#dfa400"}
                    loading={categLoading}
                    size={30}
                  />
                </div>
              ) : (
                <div className="row g-2 cars-grid-style">
                  {product &&
                    product
                      .filter((data) => {
                        if (searchTerm === "") {
                          //for filtering products on search
                          return data;
                        } else if (
                          data.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          data.description
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return data;
                        }
                      })
                      .map((data, index) => (
                        <>
                          <MenuItem
                            key={index}
                            name={data.name}
                            describe={data.short_description}
                            currency={"ريال"}
                            id={data.id}
                            price={data.price}
                            active={data.is_active}
                            image={data.image?.thumb}
                            calories={data.calories}
                            indication={data.indication}
                          />{" "}
                        </>
                      ))}
                  {noProduct == true || product.length == 0 ? (
                    <p className="noProduct"> {t("party.no_product")} </p>
                  ) : null}{" "}
                </div>
              )}
            </div>
          </div>{" "}
        </div>
      )}{" "}
      {/* <Footer /> */}
    </div>
  );
}

export default MainMenu;
