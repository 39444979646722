import "../Styles/CompanySales.css";
import Navbar from "../Components/Navbar";
import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { Banners, clients, salesMenu, salesService, phones } from "../api-nano";
import call from "../Images/call.svg";
import SalesComp from "../Components/SalesComp";
import CompanyServices from "../Components/CompanyServices";
import SalesCustomers from "../Components/SalesCustomers";
import Slider from "../Components/Slider";

function Branches(props) {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);

  const [banners, setBanners] = useState([]);
  const [client, setClient] = useState([]);

  const [saleMenu, setSalesMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [phone, setphone] = useState([]);
  const [salesServices, setSalesServices] = useState([]);

  useEffect(() => {
    setLoading(true);
    let language = i18n.language == "ar" ? "ar" : "en";
    let countryId = props.country == 0 ? 1 : 2;

    Banners("sales", language, countryId).then((response) => {
      const data = response.data.data;
      setBanners(data);
    });

    clients().then((response) => {
      const data = response.data.data.salesClients;
      setClient(data);
    });

    salesMenu().then((response) => {
      const data = response.data.data.salesMenus;

      setSalesMenu(data);
    });

    phones().then((response) => {
      const data = response.data.data;
      setphone(data);
    });

    salesService().then((response) => {
      const data = response.data.data.salesServices;

      setSalesServices(data);
      setLoading(false);
    });
  }, [page, i18n.language, props.country]);

  return (
    <div>
      <Slider banners={banners} />

      {loading ? (
        <div className="loader">
          <BeatLoader color={"#dfa400"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="aboutusBody container-fluid fluid">
          <div className={"bodyTitle"}>
            <p> {t("companySales.sales")} </p>{" "}
            <div>
              <p> {t("companySales.mainText")} </p>{" "}
            </div>
          </div>
          <div className="corporateNav cstmr">
            <p
              onClick={() => setPage(0)}
              className={page == 0 ? "Customeractive" : null}
            >
              {" "}
              {t("companySales.menus")}{" "}
            </p>

            <p
              onClick={() => setPage(1)}
              className={page == 1 ? "Customeractive" : null}
            >
              {" "}
              {t("companySales.services")}{" "}
            </p>

            <p
              onClick={() => setPage(2)}
              className={page == 2 ? "Customeractive" : null}
            >
              {" "}
              {t("companySales.clients")}{" "}
            </p>

            <p
              onClick={() => setPage(3)}
              className={page == 3 ? "Customeractive" : null}
            >
              {" "}
              {t("companySales.contact_us")}{" "}
            </p>
          </div>
          {page == 0 ? (
            <div className="row gy-4">
              {saleMenu &&
                saleMenu.map((data, index) => (
                  <SalesComp
                    key={index}
                    name={i18n.language == "ar" ? data.arName : data.enName}
                    describe={
                      i18n.language == "ar"
                        ? data.arDescription
                        : data.enDescription
                    }
                    image={data.image}
                    min={data.minPersonsNumber}
                    price={data.pricePersonSAR}
                    id={data.id}
                  />
                ))}{" "}
              {saleMenu.length == 0 ? (
                <div className="noData">
                  <p> {t("companySales.no_result")} </p>
                </div>
              ) : null}
            </div>
          ) : null}
          {page == 1 ? <CompanyServices salesServices={salesServices} /> : null}{" "}
          {page == 2 ? <SalesCustomers client={client} /> : null}{" "}
          {page == 3 ? (
            <div>
              <div
                style={{
                  marginBottom: "5em",
                }}
              >
                <div className={props.classname}>
                  <p className="cuHeader"> {t("contact.KnowMore2")} </p>
                  <div>
                    <p
                      style={{
                        marginBottom: "0em",
                      }}
                      className="cuTitles"
                    >
                      {" "}
                      {t("contact.administration")}{" "}
                    </p>
                    <div className="contactPhone">
                      <div
                        style={{
                          marginBottom: "1em",
                          marginTop: "0",
                        }}
                      >
                        <p> {t("contact.administration-text")} </p>
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div>
                    <p className="cuTitles"> {t("contact.byPhone")} </p>
                    <div className="contactPhone">
                      <div
                        style={{
                          marginTop: "2em",
                        }}
                      >
                        <p> 966531833995+ </p>{" "}
                        <a href={`tel:966531833995+`}>
                          {" "}
                          <button>
                            {" "}
                            <img
                              className={
                                i18n.language == "ar" ? "callIcon" : null
                              }
                              src={call}
                            />
                          </button>
                        </a>
                      </div>

                      {phone.mdiafPhone2 ? (
                        <div>
                          <p> 771716771 </p>{" "}
                          <a href={`tel:771716771`}>
                            {" "}
                            <button>
                              {" "}
                              <img
                                className={
                                  i18n.language == "ar" ? "callIcon" : null
                                }
                                src={call}
                              />
                            </button>
                          </a>
                        </div>
                      ) : null}
                    </div>{" "}
                  </div>
                </div>{" "}
              </div>
            </div>
          ) : null}
        </div>
      )}

      {/* <Footer /> */}
    </div>
  );
}

export default Branches;
