// Todo: I change all the page with new design and fixed the map 
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BeatLoader } from "react-spinners";
import ChangeType from "../Components/ChangeType";
import Footer from "../Components/Footer";
import logo from "../Images/Login/logo.svg";
import logoRemoved from "../Images/logo-removed.png";
import "swiper/css";
import "swiper/css/navigation";
import "../Styles/FromBranch.css";
import { getDepartments } from "../api-nano";
import { isEmptyArray } from "formik";

// Custom marker icon
const customIcon = new L.Icon({
  iconUrl: logoRemoved,
  iconSize: [70, 70],
  iconAnchor: [20, 41],
  popupAnchor: [15, -40],
});

function FromBranch(props) {
  const { t, i18n } = useTranslation();
  const [branch, setBranch] = useState(JSON.parse(localStorage.getItem("nearBranches")) || []);
  const [changeModal, setChangeModal] = useState(false);
  const [title, setTitle] = useState("home.option-branch");
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState({ lat: 24.7136, lng: 46.6753 }); // Default to Riyadh
  const history = useNavigate();

  useEffect(() => {
    console.log("props:", props)
    window.scrollTo(0, 0);
    if (localStorage.getItem("typeId") == 2) {
      setTitle("home.option-home");
    }
    
    if (branch.length > 0) {
      setPosition({
        lat: parseFloat(branch[0]?.latitude) || 24.7136,
        lng: parseFloat(branch[0]?.longitude) || 46.6753,
      });
    }

    //? to display shops if user dosen't make login
    if(branch.length === 0){
      getDepartments().then((response) => {
        setBranch(response.data.data);
        // console.log("****************", response.data.data);

        setPosition({
          lat: parseFloat(response.data.data[0]?.latitude),
          lng: parseFloat(response.data.data[0]?.longitude),
        });
      });
    }
    
    setLoading(false);
  }, []);

  //? I change this to click with out checking
  function chooseBranch(id) {
    const token = localStorage.getItem("token");
    let branchId = localStorage.getItem("branchId");

    if (token && props.cartItems !== 0 && branchId && branchId !== id) {
      setChangeModal(true);
    } else {
      localStorage.setItem("branchId", id);
      history("/menu");
    }
  }

  function handleClear() {
    // Implement clear cart logic here
  }

  if (loading) {
    return (
      <div className="loader">
        <BeatLoader color={"#dfa400"} loading={loading} size={30} />
      </div>
    );
  }

  return (
    <div>
      {/* {changeModal && (
        <ChangeType
          text="change_type"
          handleClear={handleClear}
          setModal={setChangeModal}
        />
      )} */}
      <div className="container-all Address">
        <div className="addressNav branchesPick container-fluid fluid">
          <p>
            <Link to="/"> {t("address.home")} </Link>
            {i18n.language == "ar" ? (
              <IoIosArrowBack style={{ color: "#4F4F4F" }} />
            ) : (
              <IoIosArrowForward style={{ color: "#4F4F4F" }} />
            )}
            <span> {t(title)} </span>
          </p>
        </div>
        <div className="mapMargin">

          <div className="branchesSlider">
            <div className="verticalAlign">
              {branch.length !== 0 && (
                <Swiper
                  navigation={true}
                  dir={i18n.language == "ar" ? "rtl" : "ltr"}
                  spaceBetween={10}
                  key={i18n.language}
                  breakpoints={{
                    530: { slidesPerView: 1, spaceBetween: 20 },
                    730: { slidesPerView: 2, spaceBetween: 20 },
                    1160: { slidesPerView: 3, spaceBetween: 20 },
                  }}
                  modules={[Navigation]}
                  className={i18n.language == "ar" ? "myCarousel branchesCrsl btnAr" : "myCarousel branchesCrsl btnEn"}
                >
                  {branch.map((data, index) => (
                    <SwiperSlide key={index}>
                      <div style={{ margin: "0.5em 0", paddingBottom: "1em" }}>
                        <div className="branches">
                          <div style={{ padding: "1em" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div
                                className="branchImg"
                                style={{ backgroundImage: `url(${data.image?.original ?? logoRemoved})` }}
                              ></div>
                              <div
                                className="branchDetails"
                                style={i18n.language == "ar" ? { marginRight: "1em" } : { marginLeft: "1em" }}
                              >
                                <p className="branchName">{data.name}</p>
                                <p className={data.is_active ? "branchState" : "branchState branchStateClosed"}>
                                  {data.is_active ? t("branches.open") : t("branches.Closed")}
                                </p>
                              </div>
                            </div>
                            <p className="branchPosition">
                              <span style={i18n.language == "ar" ? { marginLeft: "0.5em" } : { marginRight: "0.5em" }}>
                                <HiOutlineLocationMarker size={30} />
                              </span>
                              {data.address}
                            </p>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <div className="menubottom">
                              <button
                                // onClick={() => chooseBranch(data.id)}
                                onClick={() => {
                                  localStorage.setItem("branchId", data.id);
                                  history("/menu");
                                }}
                                style={{ height: "65px" }}
                                className={i18n.language == "en" ? "enArrow-button2 arrow-button2" : "arrow-button2"}
                              >
                                {t("branches.choose")}
                                <span className="arrow2"></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>

          <MapContainer 
            center={[position.lat, position.lng]} 
            zoom={13} 
            style={{ height: '800px', width: '100%', zIndex: "5" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {branch.map((data, index) => (
              <Marker 
                key={index} 
                position={[parseFloat(data.latitude), parseFloat(data.longitude)]} 
                icon={customIcon}
              >
                <Popup className="map-popup">
                  <div >
                    <h3>{data.name}</h3>
                    <p>{data.address}</p>
                    <span style={data.is_active? {color: "#0dbf0d"} : {color: "red"}}>{data.is_active ? t("branches.open") : t("branches.Closed")}</span>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>


        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default FromBranch;

