// Todo:  Add a Order details Jsx codes

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOrderDetails } from '../api-nano'; // You'll need to create this API function
import '../Styles/OrderDetails.css';
import { BeatLoader } from 'react-spinners';
import "../Styles/UserProfile.css";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";

function OrderDetails(props) {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await getOrderDetails(id);
        setOrder(response.data);
        console.log("***********",response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching order details:', error);
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div className="loader orderDetailsLoader">
        <BeatLoader color={"#dfa400"} loading={loading} size={30} />
      </div>
    );
  }

  if (!order) {
    return <div className="orderNotFound">{t('orderDetails.notFound')}</div>;
  }

  return (
    <div>
    {" "}
    {modal ? (
      <Logout setModal={setModal} setCartItem={props.setCartItem} />
    ) : null}{" "}
    <div className="container-profile-header userProfile">
      <UserNav setModal={setModal} nav={3} />{" "}
      <div className="orderDetails">
              <button className="backButton" onClick={handleGoBack}>
            {t('orderDetails.back')}
          </button>
        <h1>{t('orderDetails.title', { id: order.id })}</h1>
        <div className="orderInfo">
          <div className="infoGroup">
            <h2>{t('orderDetails.generalInfo')}</h2>
            <p><strong>{t('orderDetails.date')}:</strong> {order.created_at}</p>
            <p><strong>{t('orderDetails.status')}:</strong> {order.payment_state_name}</p>
          </div>
          <div className="infoGroup">
            <h2>{t('orderDetails.customerInfo')}</h2>
            <p><strong>{t('orderDetails.name')}:</strong> {order.billing_firstname} {order.billing_lastname}</p>
            <p><strong>{t('orderDetails.phone')}:</strong> {order.billing_phone}</p>
            <p><strong>{t('orderDetails.address')}:</strong> {order.shipping_address_1}</p>
          </div>
          <div className="infoGroup">
            <h2>{t('orderDetails.orderInfo')}</h2>
            <p><strong>{t('orderDetails.subtotal')}:</strong> {order.subtotal}</p>
            <p><strong>{t('orderDetails.taxes')}:</strong> {order.order_taxes}</p>
            <p><strong>{t('orderDetails.tip')}:</strong> {order.tip_amount}</p>
            <p><strong>{t('process.delivery_charge')}:</strong> {order.shipping_price}</p>
            <p><strong>{t('orderDetails.total')}:</strong> {order.order_total} {i18n.language === 'ar' ? order.arCurrency : order.enCurrency}</p>

          </div>
        </div>
        <div className="orderItems">
          <h2>{t('orderDetails.items')}</h2>
          <table>
            <thead>
              <tr>
                <th>{t('orderDetails.item-name')}</th>
                <th>{t('orderDetails.item-content')}</th>
                <th>{t('orderDetails.quantity')}</th>
                <th>{t('orderDetails.price')}</th>
              </tr>
            </thead>
            <tbody>
              {order.items.data.map((item, index) => (
                <tr key={index}>
                  <td>{item.custom_name}</td>
                  {/* <td>{item.option_values[0].values[0].name}</td> */}
                  <td>{item.units_name} {item.option_values.map((itm) => ( " - " + itm.values.map((e) => ' ' + e.name)))}</td>
                  <td>{item.quantity}</td>
                  <td>{item.subtotal} {i18n.language === 'ar' ? order.arCurrency : order.enCurrency}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  );
}

export default OrderDetails;