/* eslint-disable no-undef */
import "../Styles/CartProcess.css";
import "../Styles/Checkout.css";

import { useState, useEffect, useRef } from "react";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { IoWalletOutline } from "react-icons/io5";
import { BsCash } from "react-icons/bs";
import { BsCreditCard } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { json, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { TiTick } from "react-icons/ti";
import {
  Get_Basket,
  checkout_step,
  checkout_step_details,
  payment_methods,
} from "../api-nano";

function CartProcess31(props) {
  const [active, setActive] = useState(0);
  const { t, i18n } = useTranslation();
  const [cart, setCart] = useState();
  const history = useNavigate();
  const [deliveryPrice, setDeliveryPrice] = useState();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [wallet, setWallet] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState();
  const [onlineType, setOnlineType] = useState();

  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [msgEn2, setMsgEn2] = useState();
  const [msgAr2, setMsgAr2] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [errorMsgModel2, setErrorMsgModel2] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("paymentMethod")
      ? localStorage.getItem("paymentMethod")
      : null
  );

  const [createBool, setCreateBool] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [freeMeal, setFreeMeal] = useState(false);
  const [offer, setOffer] = useState(
    localStorage.getItem("offer") ? localStorage.getItem("offer") : false
  );
  const [phone, setPhone] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [breakdown, setBreakdown] = useState();
  const [cardValid, setCardValid] = useState(false);

  const [code, setCode] = useState(
    localStorage.getItem("promo") ? localStorage.getItem("promo") : ""
  );
  const [tipAmount, setTipAmount] = useState("");

  const [currency, setCurrency] = useState({
    ar: localStorage.getItem("currencyAr"),
    en: localStorage.getItem("currencyEn"),
  });

  const codeRef = useRef();
  const tipRef = useRef();

  const walletRef = useRef();
  const cashRef = useRef();
  const tapRef = useRef();

  useEffect(() => {
    const token = localStorage.getItem("token");
    localStorage.removeItem("cardToken");

    const delivery = localStorage.getItem("order_details");
    setDeliveryPrice(JSON.parse(delivery).delivery);

    Get_Basket(token)
      .then((response) => {
        console.log("the carts is", response);
        const data = response.data;
        setCart(response.data);

        const addressData = JSON.parse(localStorage.getItem("addressData") || "{}");

        const detailsData = {
          shipping_address_1: addressData.shipping_address_1 || "اب, اليمن",
          shipping_country: addressData.shipping_country || "YE",
          shipping_state: addressData.shipping_state || "428",
        };

        checkout_step_details(token, detailsData)
          .then((shippingResponse) => {
            console.log("shipping methods:", shippingResponse);
      
            
            // console.log(shippingResponse)
            // console.log("-------------",shippingResponse.data.cart_totals?.tax?.title)
            // console.log("-------------",shippingResponse.data.cart_totals?.total?.value)
            let breakDown = {
              originalPrice: shippingResponse.data.cart_totals?.subtotal.value,
              tax: shippingResponse.data.cart_totals?.tax?.title,
              taxValue: shippingResponse.data.cart_totals?.tax?.value,
              deliveryPrice: shippingResponse.data.cart_totals?.shipping_total.value,
              tipAmount: shippingResponse.data.cart_totals?.tip.value,
              totalPrice: shippingResponse.data.cart_totals?.total.value
            };
            setBreakdown(breakDown);
            setLoading(false);
            payment_methods(token)
              .then((response) => {
                console.log("the payment methods is", response);

                setPaymentMethod(response.data.data);
                if (localStorage.getItem("paymentMethod")) {
                  setSelectedOption(localStorage.getItem("paymentMethod"));
                } else {
                  // setSelectedOption("cash");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((error) => {
            setLoading(false);
          });
      });
  }, []);

  async function handleOffer() {
    setCreateBool(false);
    const token = localStorage.getItem("token");
    setErrorMsgModel2(false);
    setOffer(false);
    localStorage.setItem("offer", false);

    if (selectedOption == null) {
      setErrorMsgModel(true);
      setMsgEn("Please choose payment method");
      setMsgAr("قم باختيار طريقة الدفع");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else {
      const storedAddressData = JSON.parse(localStorage.getItem("addressData") || "{}");

      const data = {
        step: "details_shiping_coupon",
        shipping_address_1: storedAddressData.shipping_address_1 || "",
        shipping_country: storedAddressData.shipping_country || "YE",
        shipping_state: storedAddressData.shipping_state || "428",
        coupon_code: codeRef.current.value,
        tip_amount: tipRef.current.value
      };

      try {
        const response = await checkout_step_details(token, data);
        console.log("the coupon's response step is", response);

        if (response.data.code === 200) {
            // Update the breakdown state with the new data
            const newBreakdown = {
              originalPrice: response.data.cart_totals.subtotal.value,
              tax: response.data.cart_totals.tax.title,
              taxValue: response.data.cart_totals.tax.value,
              deliveryPrice: response.data.cart_totals.shipping_total.value,
              tipAmount: response.data.cart_totals.tip.value,
              totalPrice: response.data.cart_totals.total.value
            };
            setBreakdown(newBreakdown);
            setTipAmount(tipRef.current.value); // Update the tip amount state
        } else {
          // Handle any other status codes if needed
        }
      } catch (err) {
        console.log("the error", err);
        setErrorMsgModel(true);
        setMsgEn("An error occurred while processing your request");
        setMsgAr("حدث خطأ أثناء معالجة طلبك");
        setTimeout(function () {
          setErrorMsgModel(false);
        }, 2000);
      }
    }
  }

  function checkOffer(cardToken) {
    const token = localStorage.getItem("token");
    setSubmitLoading(true);
    if (selectedOption == null) {
      setErrorMsgModel(true);
      setMsgEn("Please choose payment method");
      setMsgAr("برجاء اختيار طريقة الدفع");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else {
      const data = {
        ...JSON.parse(localStorage.getItem("addressData")),
        payment_method_id: selectedOption == "cash" ? paymentMethod[0]?.id : paymentMethod[1]?.id,
        step: "pay",
        tip_amount: tipRef.current.value
      };
  
      checkout_step(token, data, "pay")
        .then((response) => {
          console.log("Checkout step response:", response);
          if (response.data.status === false) {
            setErrorMsgModel(true);
            setMsgAr(response.data.error);
            setMsgEn(response.data.message);
            setSubmitLoading(false);
            setTimeout(function () {
              setErrorMsgModel(false);
            }, 5000);
          } else {
            createOrder(cardToken)
              .then(() => {
                // Redirect to CartProcess4 page without showing a notification
                history("/cart-process-4");
              })
              .catch((error) => {
                console.error("Order creation error:", error);
                setErrorMsgModel(true);
                setMsgAr("فشل في إضافة الطلب");
                setMsgEn("Failed to add the order");
              })
              .finally(() => {
                setSubmitLoading(false);
              });
          }
        })
        .catch((error) => {
          console.error("Checkout step error:", error);
          setErrorMsgModel(true);
          setMsgAr("حدث خطأ أثناء معالجة الطلب");
          setMsgEn("An error occurred while processing the request");
          setSubmitLoading(false);
          setTimeout(function () {
            setErrorMsgModel(false);
          }, 5000);
        });
    }
  }

  function createOrder(cardToken) {
    setDiscount(false);
    setFreeMeal(false);
    const token = localStorage.getItem("token");
    const detail = JSON.parse(localStorage.getItem("order_details"));
    setSubmitLoading(true);

    if (selectedOption == null) {
      setErrorMsgModel(true);
      setMsgEn("Please choose payment method");
      setMsgAr("برجاء اختيار طريقة الدفع");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else {
      var data2 = "";

      if (parseInt(localStorage.getItem("typeId")) === 4) {
        data2 = {
          token: "",
          remainPaymentMethod: null,
          paymentMethod: selectedOption,
          code: codeRef.current.value,
          notes: "",
          date: "",
          now: detail.now.toString(),
          time: "",
          token: "",
          carOption: true,
          carModel: localStorage.getItem("carmodel"),
          carColor: localStorage.getItem("carColor"),
          carNumber: localStorage.getItem("carNumber"),
          carNotes: localStorage.getItem("carNotes"),
        };
      } else if (parseInt(localStorage.getItem("typeId")) === 2) {
        data2 = {
          token: "",
          remainPaymentMethod: null,
          paymentMethod: selectedOption,
          code: codeRef.current.value,
          notes: "",
          date: detail.date,
          now: detail.now.toString(),
          time: detail.time,
          addressId: parseInt(localStorage.getItem("addressId")),
        };
      } else {
        data2 = {
          token: "",
          remainPaymentMethod: null,
          paymentMethod: selectedOption,
          code: codeRef.current.value,
          notes: "",
          date: detail.date,
          now: detail.now.toString(),
          time: detail.time,
        };
      }

      window.localStorage.setItem("ORDER", JSON.stringify(data2));
      const pay_type = localStorage.getItem("paymentMethod") ?? "";
      const data = JSON.parse(localStorage.getItem("addressData"));
      console.log(data);
      checkout_step(
        token,
        {
          ...data,
          payment_method_id:
            pay_type == "cash" ? paymentMethod[0]?.id : paymentMethod[1]?.id,
          step: "pay",
          tip_amount: tipRef.current.value
        },
        "pay"
      )
        .then((response) => {
          console.log("the response is", response);
          localStorage.setItem("transactionType", "order");

          localStorage.removeItem("promo");
          localStorage.removeItem("paymentMethod");

          localStorage.removeItem("process");

          localStorage.removeItem("timeNow");
          localStorage.removeItem("cartMessage");
          localStorage.removeItem("offer");

          localStorage.removeItem("orderDate");
          localStorage.removeItem("deliveryType");

          props.setCartChange(!props.cartChange);

          setMsgAr("عملية الدفع تمت بنجاح");
          setMsgEn("Payment completed successfully");
          setSubmitLoading(false);
          setTimeout(function () {
            // history("/payment-success");

            props.setNav(3);
            localStorage.setItem(
              "process",
              3
            ); 
          }, 2000);
        })
        .catch((error) => {
          setMsgAr("حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");
          setSubmitLoading(false);
          setTimeout(function () {
            props.setCartChange(!props.cartChange);

            history("/payment-fail");
          }, 2000);
        });
    }
  }

  const handleOptionChange = (changeEvent, n) => {
    console.log("the payment method is ", paymentMethod);
    console.log(changeEvent.target.value);
    localStorage.setItem("paymentMethod", changeEvent.target.value);
    setSelectedOption(changeEvent.target.value);
    setActive(n);
  };

  //called in checkOffer function
  //handles errors
  function getError(msg) {
    if (msg === "branch does not support branch pick up") {
      setErrorMsgModel(true);
      setMsgEn("branch not support branch pick up");
      setMsgAr("الفرع لا يدعم خدمة توصيل الطالبات");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not support delivery") {
      setErrorMsgModel(true);
      setMsgAr("الفرع لا يدعم خدمة توصيل المنزل");
      setMsgEn("branch not support delivery");
      setSubmitLoading(false);

      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not exist") {
      setErrorMsgModel(true);
      setMsgAr("الفرع خارج نطاق الخدمة");
      setMsgEn("branch not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not active") {
      setErrorMsgModel(true);
      setMsgAr("الفرع غير متاح حاليا");
      setMsgEn("branch not active");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
      setSubmitLoading(false);
    } else if (msg === "branch not open") {
      setErrorMsgModel(true);
      setMsgAr("الفرع مغلق الأن");
      setMsgEn("branch not open");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "meal not exist") {
      setErrorMsgModel(true);
      setMsgAr("الوجبة غير متاحة حاليا");
      setMsgEn("meal not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "meal not active") {
      setErrorMsgModel(true);

      setMsgAr("الوجبة غير متاحة حاليا");
      setMsgEn("meal not active");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "product not delivery") {
      setErrorMsgModel(true);
      setMsgAr("الوجبة غير متاحة للتوصيل");
      setMsgEn("product not delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "additional not delivery") {
      setErrorMsgModel(true);
      setMsgAr("الإضافات غير متاحة للتوصيل");
      setMsgEn("additional not delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "extra not exist") {
      setErrorMsgModel(true);
      setMsgAr("الزيادات غير متاحة");
      setMsgEn("extra not exist");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "extra not belong to product") {
      setErrorMsgModel(true);
      setMsgAr("الزيادات غير متوفرة مع الوجبة");
      setMsgEn("extra not belong to product");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "The selected code is invalid.1") {
      setInvalid(true);

      //   logEvent(analytics, "coupon_code_failed", {
      //     phone: phone,
      //     code: codeRef.current.value,
      //   });
      /*
            window.webengage.track("Coupon Code Failed", {
              phone: phone,
              code:codeRef.current.value
            })*/

      setErrorMsgModel(true);
      setMsgAr("الكود غير صحيح");
      setMsgEn("The selected code is invalid.");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "الرمز الترويجي غير صحيح") {
      setInvalid(true);

      //   logEvent(analytics, "coupon_code_failed", {
      //     phone: phone,
      //     code: codeRef.current.value,
      //   });
      /*
            window.webengage.track("Coupon Code Failed", {
              phone: phone,
              code:codeRef.current.value
            })*/

      setErrorMsgModel(true);
      setMsgAr("الكود غير صحيح");
      setMsgEn("The selected code is invalid.");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "address not valid") {
      setErrorMsgModel(true);
      setMsgAr("العنوان غير صحيح");
      setMsgEn("address not valid");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "time not in branch time") {
      setErrorMsgModel(true);
      setMsgAr("الفرع مغلق في الوقت المحدد");
      setMsgEn("time not in branch time");

      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not support fast delivery") {
      setErrorMsgModel(true);
      setMsgAr("الفرع لا يوفر التوصيل السريع");
      setMsgEn("branch not support fast delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "Branch is busy right now,عفوًا الفرع مشغول الآن") {
      setErrorMsgModel(true);
      setMsgAr("الفرع مشغول، تقدر تطلب بعد الزحام.");
      setMsgEn(
        "This branch is currently busy. You can order for later when branch is not busy."
      );

      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "branch not support ordinary delivery") {
      setErrorMsgModel(true);
      setMsgAr("الفرع لا يوفر التوصيل العادي");
      setMsgEn("branch not support ordinary delivery");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "Transaction has been failed,please try again") {
      localStorage.setItem("failType", "order");
      localStorage.removeItem("cardToken");

      setErrorMsgModel(true);
      setMsgAr(" حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
      setMsgEn("Transaction has been failed,please try again");
      setSubmitLoading(false);
      setTimeout(function () {
        props.setCartChange(!props.cartChange); //triggers api call in (App.js) to rerender cart

        history("/payment-fail");
      }, 2000);
    } else if (msg === "طلبك غير مستوفي لشروط الرمز الترويجي") {
      setInvalid(true);

      //   logEvent(analytics, "coupon_code_failed", {
      //     phone: phone,
      //     code: codeRef.current.value,
      //   });
      /*
      window.webengage.track("Coupon Code Failed", {
        phone: phone,
        code:codeRef.current.value
      })
*/

      setErrorMsgModel(true);
      setMsgAr("طلبك غير مستوفي لشروط الرمز الترويجي");
      setMsgEn("Your order doesn't meet this promo code's conditions");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else if (msg === "الرمز الترويجي غير متاح في هذا الفرع") {
      setInvalid(true);

      //   logEvent(analytics, "coupon_code_failed", {
      //     phone: phone,
      //     code: codeRef.current.value,
      //   });
      /*
            window.webengage.track("Coupon Code Failed", {
              phone: phone,
              code:codeRef.current.value
            })*/

      setErrorMsgModel(true);
      setMsgAr("الرمز الترويجي غير متاح في هذا الفرع");
      setMsgEn("Promo code is not available in this branch");
      setCode("");
      localStorage.setItem("promo", "");
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    } else {
      setErrorMsgModel(true);
      setMsgAr(msg);
      setMsgEn(msg);
      setSubmitLoading(false);
      setTimeout(function () {
        setErrorMsgModel(false);
      }, 2000);
    }
  }

  return (
    <div className="cartProcess">
      {" "}
      {loading ? (
        <div className="loader">
          <BeatLoader color={"#dfa400"} loading={loading} size={30} />
        </div>
      ) : (
        <>
          <div className="container-fluid fluid ">
            <div className="cpMiddle-title2">
              <p> {t("process.details")} </p>{" "}
              <div className="cpPayment">
                <div className="row ">
                  <div
                    style={{
                      display: "block",
                      position: "relative",
                    }}
                    className="col-md-6 cpPayment-div2 "
                  >
                    <p> {t("process.payment_type")} </p>{" "}
                    <div
                      className={
                        localStorage.getItem("paymentMethod") == "cash"
                          ? "activeChoice"
                          : null
                      }
                      onClick={() => cashRef.current.click()}
                    >
                      <label>
                        {" "}
                        <BsCash size={23} className="labelIcon" />{" "}
                        {paymentMethod && paymentMethod[0].name}{" "}
                      </label>

                      <input
                        value="cash"
                        ref={cashRef}
                        id="cash"
                        onChange={(e) => handleOptionChange(e, 1)}
                        type="radio"
                        name="payment"
                        defaultChecked={
                          localStorage.getItem("paymentMethod") == "cash"
                            ? true
                            : false
                        }
                      />
                    </div>{" "}
                    <div
                      className={
                        localStorage.getItem("paymentMethod") == "checkout"
                          ? "activeChoice"
                          : null
                      }
                      onClick={() => tapRef.current.click()}
                    >
                      <label>
                        {" "}
                        <BsCreditCard size={23} className="labelIcon" />{" "}
                        {/* {t("process.credit")}{" "} */}
                        {paymentMethod && paymentMethod?.length > 1
                          ? paymentMethod[1].name
                          : ""}{" "}
                      </label>

                      <input
                        value="checkout"
                        id="tap"
                        ref={tapRef}
                        onChange={(e) => handleOptionChange(e, 3)}
                        type="radio"
                        name="payment"
                        defaultChecked={
                          localStorage.getItem("paymentMethod") == "checkout"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div>
                      <p> {t("process.code")} </p>
                      <div className="promo">
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            type="text"
                            ref={codeRef}
                            className={errorMsgModel2 ? "invalid" : null}
                            value={code}
                            onChange={(e) => {
                              setCode(e.target.value);
                              localStorage.setItem("promo", e.target.value);
                            }}
                          />{" "}
                          {offer && localStorage.getItem("showIcn") == "true" ? (
                            <TiTick
                              size={30}
                              className={
                                i18n.language == "ar" ? "tick" : "tickEn"
                              }
                            />
                          ) : null}
                        </div>{" "}
                        <button onClick={handleOffer}>
                          {" "}
                          {t("profile.confirm")}{" "}
                        </button>{" "}
                      </div>
                    </div>
                    {" "}
                    {/* !!!!!! Can you fix this div tag belo to add a tip_amount to data !!!!!!!!!!!!!!!!11111 */}

                    {/* //? اكرامية للسائق */}
                    <div style={{display: "none"}}>
                        <p>{t("process.tip")}</p>
                        <div className="tip">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              ref={tipRef}
                              className={errorMsgModel2 ? "invalid" : null}
                              value={tipAmount}
                              onChange={(e) => {
                                setTipAmount(e.target.value);
                                // localStorage.setItem("tip", e.target.value);
                              }}
                            />
                            {offer && localStorage.getItem("showIcn") == "true" ? (
                              <TiTick
                                size={30}
                                className={i18n.language == "ar" ? "tick" : "tickEn"}
                              />
                            ) : null}
                          </div>
                          <button onClick={handleOffer}>{t("profile.confirm")}</button>
                        </div>
                      </div>




                    {/* <div>
                      <p> {t("process.tip")} </p>
                      <div className="tip">
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            type="text"
                            ref={tipRef}
                            className={errorMsgModel2 ? "invalid" : null}
                            value={tipAmount}
                            onChange={(e) => {
    const newTipAmount = e.target.value;
    setTipAmount(newTipAmount);
    localStorage.setItem("tip", newTipAmount);
    
    // Update the breakdown
    if (breakdown) {
      const newTotalPrice = 
        breakdown.originalPrice + 
        breakdown.taxValue + 
        breakdown.deliveryPrice + 
        (parseFloat(newTipAmount) || 0);
      
      setBreakdown({
        ...breakdown,
        tipAmount: parseFloat(newTipAmount) || 0,
        totalPrice: newTotalPrice
      });
    }}}
                          />{" "}
                          {offer && localStorage.getItem("showIcn") == "true" ? (
                            <TiTick
                              size={30}
                              className={
                                i18n.language == "ar" ? "tick" : "tickEn"
                              }
                            />
                          ) : null}
                        </div>{" "}
                        <button onClick={handleOffer}>
                          {" "}
                          {t("profile.confirm")}{" "}
                        </button>{" "}
                      </div>
                    </div> */}
                    {" "}
                    {i18n.language == "ar" ? (
                      <span
                        style={{
                          fontSize: "14px",
                          color: "brown",
                        }}
                      >
                        {" "}
                        {errorMsgModel2 ? msgAr2 : null}{" "}
                      </span>
                    ) : (
                      <span
                        style={{
                          fontSize: "14px",
                          color: "brown",
                        }}
                      >
                        {" "}
                        {errorMsgModel2 ? msgEn2 : null}{" "}
                      </span>
                    )}
                    <div
                      style={{
                        minHeight: "100px",
                      }}
                      className={
                        errorMsgModel ? "addAlert " : " addAlert alert-hidden "
                      }
                    >
                      <span
                        style={{
                          color: "brown",
                        }}
                      >
                        {" "}
                        {i18n.language == "en" ? msgEn : msgAr}{" "}
                      </span>
                    </div>
                    <span> </span>{" "}
                  </div>
                  <div className="col-md-6 cpPayment-div1">
                    <div
                      style={{
                        padding: "1em",
                      }}
                    >
                      <p className="cpPayment-div1-title">
                        {" "}
                        {t("process.payment_summary")}{" "}
                      </p>{" "}
                      <div className="cpMoneyBreakdown">
                        <div>
                          <p> {t("process.price")} </p>{" "}
                          <p className="cpMoney">
                            {" "}
                            {breakdown?.originalPrice?.toFixed(2)} {"ريال"}{" "}
                          </p>
                        </div>{" "}
                        <div>
                          <p>
                            {" "}
                            {t("process.tax")}
                            {breakdown?.tax}{" "}
                          </p>

                          <p className="cpMoney">
                            {" "}
                            {breakdown?.taxValue?.toFixed(2)} {"ريال"}{" "}
                          </p>
                        </div>{" "}
                        
                        {/* //? أجرة التوصيل */}
                        {/* Todo: add delivery price and calculated with total price  */}
                        {/* {breakdown && (
                        <div>
                          <p> {t("process.delivery_charge")} </p>{" "}
                          <p className="cpMoney">
                            {" "}
                            {breakdown.deliveryPrice}  {"ريال"}{" "}
                          </p>
                        </div>
                      )} */}

                    {/* //? اكرامية للسائق */}
                    {/* Todo: add delivery price and calculated with total price  */}
                    {/* {breakdown && (
                      <div>
                        <p>{t("process.tip")}</p>
                        <p className="cpMoney">
                          {breakdown.tipAmount} {"ريال"}
                        </p>
                      </div>
                      )} */}
                      

                        {/*                         
                        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1
                        {breakdown && (
                          <div className="price-breakdown">
                            <p>Original Price: {breakdown.originalPrice} {currency[i18n.language]}</p>
                            <p>Tax ({breakdown.tax}): {breakdown.taxValue} {currency[i18n.language]}</p>
                            <p>Delivery Price: {breakdown.deliveryPrice} {currency[i18n.language]}</p>
                            <p>Total Price: {breakdown.totalPrice} {currency[i18n.language]}</p>
                          </div>
                        )}
                        !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!111 
                        */}

                        {/* {breakdown && (
  <div className="price-breakdown">
    <p>Original Price: {breakdown.originalPrice} {currency[i18n.language]}</p>
    <p>Tax ({breakdown.tax}): {breakdown.taxValue} {currency[i18n.language]}</p>
    <p>Delivery Price: {breakdown.deliveryPrice} {currency[i18n.language]}</p>
    <p>Tip: {breakdown.tipAmount} {currency[i18n.language]}</p>
    <p>Total Price: {breakdown.totalPrice} {currency[i18n.language]}</p>
  </div>
)} */}

                        {offer &&
                        (localStorage.getItem("offerType") == "discount" ||
                          localStorage.getItem("offerType") ==
                            "freeMealsDiscount" ||
                          localStorage.getItem("offerType") ==
                            "discountCashBack") ? (
                          <div>
                            <p> {t("process.discount")} </p>{" "}
                            <p className="cpMoney">
                              {" "}
                              {parseFloat(
                                localStorage.getItem("discAmount")
                              )?.toFixed(2)}{" "}
                              {"ريال"}{" "}
                            </p>
                          </div>
                        ) : null}
                      </div>{" "}
                      <hr />
                      <div
                        style={{
                          transform: "none",
                        }}
                        className="cpTotaldiv"
                      >
                        <p className="cpTotalP"> {t("process.total")} </p>{" "}
                        <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                          <p className="cpTotal">
                            {" "}
                            {offer &&
                            (localStorage.getItem("offerType") == "discount" ||
                              localStorage.getItem("offerType") ==
                                "freeMealsDiscount" ||
                              localStorage.getItem("offerType") ==
                                "discountCashBack")
                              ? localStorage.getItem("discountPrice")
                              : breakdown?.totalPrice?.toFixed(2)}{" "}
                          </p>{" "}
                          <p className="cpCurrency"> {"ريال"} </p>{" "}
                        </div>
                      </div>{" "}
                      {selectedOption == "checkout" ||
                      (selectedOption == "wallet" &&
                        (offer &&
                        (localStorage.getItem("offerType") == "discount" ||
                          localStorage.getItem("offerType") ==
                            "discountCashBack" ||
                          localStorage.getItem("offerType") ==
                            "freeMealsDiscount")
                          ? wallet < localStorage.getItem("discountPrice")
                          : wallet < breakdown.totalPrice) &&
                        onlineType == "checkout") ? (
                        <div
                          className="creditCard"
                          style={{
                            marginTop: "1.5em",
                          }}
                        >
                          <Frames
                            cardValidationChanged={(e) => {
                              setCardValid(e.isValid);
                            }}
                            config={{
                              publicKey: "pk_sbox_wxn7ld2c4dskxkd4kcpiilwo2uo",
                              schemeChoice: true,
                            }}
                            cardTokenized={(e) => {
                              checkOffer(e.token);
                            }}
                          >
                            <CardNumber />
                            <div className="date-and-code">
                              <ExpiryDate />
                              <Cvv />
                            </div>
                            {/* Or if you want to use single frames: */}{" "}
                            {/* <CardFrame /> */}
                            {submitLoading ? (
                              <div
                                style={{
                                  height: "6vh",
                                  marginTop: "2em",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                                className="loader"
                              >
                                <BeatLoader
                                  color={"#dfa400"}
                                  loading={submitLoading}
                                  size={30}
                                />{" "}
                                <p className="paymentLoad">
                                  {" "}
                                  {t("payment.processing")}{" "}
                                </p>{" "}
                              </div>
                            ) : (
                              <button
                                disabled={!cardValid}
                                id="pay-button"
                                onClick={() => {
                                  setSubmitLoading(true);
                                  if (localStorage.getItem("cardToken")) {
                                    checkOffer(
                                      localStorage.getItem("cardToken")
                                    );
                                  } else {
                                    Frames.submitCard();
                                  }
                                }}
                              >
                                {t("process.confirm_order")}{" "}
                              </button>
                            )}{" "}
                          </Frames>{" "}
                        </div>
                      ) : null}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            {selectedOption == "checkout" ||
            (selectedOption == "wallet" &&
              (offer &&
              (localStorage.getItem("offerType") == "discount" ||
                localStorage.getItem("offerType") == "discountCashBack" ||
                localStorage.getItem("offerType") == "freeMealsDiscount")
                ? wallet < localStorage.getItem("discountPrice")
                : wallet < breakdown?.totalPrice) &&
              onlineType == "checkout") ? null : (
              <>
                {" "}
                {submitLoading ? (
                  <div
                    style={{
                      height: "23vh",
                      alignItems: "unset",
                    }}
                    className="loader"
                  >
                    <BeatLoader
                      color={"#dfa400"}
                      loading={submitLoading}
                      size={30}
                    />
                  </div>
                ) : (

                  <div className="cartNext cartBack">
                  {/* Todo: Add back button on Sales basket on cart */}
                    <button
                      onClick={() => {
                        props.setNav(1);
                        localStorage.setItem(
                          "process",
                          1
                        ); 
                      }}
                    >
                      {" "}
                      {t("process.back")}{" "}
                    </button>

                    <button 
                      onClick={checkOffer} 
                      // disabled={submitLoading || !selectedOption}
                    >
                      {submitLoading ? t("process.processing") : t("process.confirm_order")}
                    </button>
                  </div>
                )}{" "}
              </>
            )}{" "}
          </div>{" "}
        </>
      )}{" "}
    </div>
  );
}

export default CartProcess31;
